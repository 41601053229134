import { Pending } from "@mui/icons-material";

export const zh = {
    APP_NAME: '物流查询系统',
    ORDER_NUMBER: '订单编号',
    E1_ORDER: 'E1订单',
    SAP_ORDER: 'SAP订单',
    DN_EDT: '预计送达时间',
    PendingShip: '待发货',
    PendingShipDefault: '待确认',
    EstimateShippingDate:'预计发货时间',
    SHIPMENTS: '个包裹',
    SHIPMENT_NUMBER: '物流单号',
    CARRIER_NAME: '物流公司',
    CATALOGNUMBER: '货号',
    QUANTITY: '数量',
    THISSHIPMENT: '在此包裹中',
    ITEMS: '件商品',
    TRACKER_NUMBER: '运输物流单号',
    shipperCity_consigneeCity: '发货地/目的地',
    offeringType: '运输方式',
    ACTIVITY: '活动细节',
    PRIVACY: '隐私政策',
    TERMS: '条款',
    NOTEXIST: '该单号不存在, 请检查订单号码或者物流单号是否正确!',
    COMMON_ERROR_MSG: '系统错误，请稍后再试!',
    NOORDERINFO: '暂无该单号数据, 请检查订单号码是否正确!',
    NOSHIPMENT: '物流信息暂未更新，请耐心等待!',
    NOSHIPMENTINFO1: "暂无此物流信息, 此包裹可能暂未发运, 请检查物流单号是否正确!",
    SalesOrderNumber: '订单号码',
    DeliveryNumber: '物流单号',
    SalesOrderNumberHolder: '请输入订单号码',
    DeliveryNumberHolder: '请输入物流单号',
    ORDER_CreatedDate: '下单日期',
    OrderHistoryLink: `
    若要查看更多订单详情，您可以点击<span
    class='App-link'
    id='gotoOrderHistory'
    >订单历史</span>查看。`,
    OrderHistoryLinkNote: '注: 如非使用您的账户订购，将不会提供订单详情。',
    PromoCenter: '促销商城',
    MemberCenter: '会员中心(积分兑换)',
    HelpCenter: '帮助&支持',
    Mobility: '掌上赛默飞',
    OrderLockedMsg: '* 订单锁定中，解锁后将会展示预计发货日期',
}
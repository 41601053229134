import React from "react";
import styles from "./OrderTracking.module.scss";
import { useIntl } from "react-intl";
import { getStatus } from "./trackingUtils";
import { Alert } from "@mui/material";

function ShipmentDetail(props) {
  const { formatMessage } = useIntl();
  const f = (id, values) => formatMessage({ id }, values);
  const { orderDetail, orderSource } = props;
  const packageItem = orderDetail && orderDetail[0];
  const [collapsed, setCollapsed] = React.useState(false);

  if (!packageItem || (packageItem.shipmentNumber && !packageItem.expressNumber) )
    return (
      <Alert severity="warning" className="alertWrapper">
        <p>{f("NOSHIPMENTINFO1")}</p>
      </Alert>
    );
  const statusObj = getStatus({
    eventDescription: packageItem.stateDescription,
    eventCode: packageItem.stateCode,
  });
  console.log("ShipmentDetail packageItem", packageItem);
  return (
    <div>
      <div className={styles.packListWrapper}>
        <div className={styles.packItemWrapper}>
          <div className={styles.shimentHeader}>
            <div className={`head3 ${styles.row1}`}>
              <label>{f("SHIPMENT_NUMBER")}:</label>
              <span>{packageItem.shipmentNumber}</span>
            </div>
            {orderSource &&
            orderSource === "SAP订单" &&
            packageItem.stateCode !== "DTD" &&
            packageItem.eta ? (
              <div className={styles.row2}>
                {statusObj.icon && (
                  <img alt={statusObj.desc} src={statusObj.icon}></img>
                )}
                <label>{f("DN_EDT")}</label>
                <span>{packageItem.eta}</span>
              </div>
            ) : (
              <div className={styles.row2}>
                {statusObj.icon && (
                  <img alt={statusObj.desc} src={statusObj.icon}></img>
                )}
                <label>{packageItem.stateDescription}</label>
                <span>{packageItem.stateTime}</span>
              </div>
            )}
          </div>
          <div
            className={
              collapsed
                ? `${styles.packItemContentWrapper} collapsed`
                : `${styles.packItemContentWrapper}`
            }
          >
            <div className={styles.shipmentWrapper}>
              <div className={styles.carrierWrapper}>
                <div className={styles.row}>
                  {packageItem.expressNumber ? (
                    <div className={styles.column1}>
                      <label className={styles.lable1}>
                        {f("TRACKER_NUMBER")}:
                      </label>
                      <span>{packageItem.expressNumber}</span>
                    </div>
                  ) : null}
                  <div className={styles.column2}>
                    <label>{f("CARRIER_NAME")}:</label>
                    <span>{packageItem.carrierName}</span>
                  </div>
                </div>

                {packageItem?.shipperCity &&
                  packageItem?.consigneeCity &&
                  packageItem?.offeringType && (
                    <div className={styles.row}>
                      <div className={styles.column1}>
                        <label className={styles.lable1}>
                          {f("shipperCity_consigneeCity")}:
                        </label>
                        <span>
                          {packageItem.shipperCity + "-"}{" "}
                          {packageItem.consigneeCity}
                        </span>
                      </div>
                      <div className={styles.column2}>
                        <label>{f("offeringType")}:</label>
                        <span>{packageItem.offeringType}</span>
                      </div>
                    </div>
                  )}
              </div>
              <div className={styles.timelineHead}>
                <label>{f("ACTIVITY")}</label>
              </div>
              <ul className={styles.timelineWrapper}>
                {packageItem?.events?.map((timeline) => {
                  return (
                    <li
                      key={timeline.eventTime}
                      className={styles.timelineItem}
                    >
                      <div className={styles.timelineSeparator}>
                        <span className={styles.timelineDot}></span>
                        <span className={styles.timelineConnector}></span>
                      </div>
                      <div className={styles.timelineContent}>
                        <div>
                          <span
                            className={`${
                              statusObj.stateCode !== "DTD" &&
                              styles.eventOfTime
                            } gray40`}
                          >
                            {timeline.eventTime}
                          </span>
                          <span
                            className="gray60"
                            style={{
                              marginLeft: "0.5rem",
                            }}
                          >
                            {timeline.status}
                          </span>
                        </div>
                        <div
                          className={
                            statusObj.stateCode !== "DTD" && styles.description
                          }
                        >
                          <span>{timeline.eventDescription}</span>
                          <span
                            style={{ paddingLeft: "5px", fontSize: "12px" }}
                          >
                            {timeline.eventLocation || ""}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShipmentDetail;
